import { Navigate, RouteObject } from 'react-router-dom';
import Home from '@/views/pages/Home.page';

const routes: RouteObject[] = [
	{
		path: '',
		element: <Home />,
		children: [
			{ path: '', element: <Navigate to="dialogues" /> },
			{ path: 'dialogues', element: <>Dialogues tab</> },
			{ path: ':id/life-plan', element: <>Life plan tab</> },
			{ path: ':id/portfolio-recommendations', element: <>Portfolio Recommendation</> },
			{ path: ':id/next-best-actions', element: <>Next Best Actions</> },
		],
	},
];

export default routes;
