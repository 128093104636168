import { FunctionComponent, ReactElement } from 'react';
import { Theme as ThemeProvider } from '@/theme';
import { QueryClient as QueryClientProvider } from '@/queries';
import { Router as RouterProvider } from '@/router';
import { FlashMessageProvider } from './context/flash-message-context/FlashMessageContext';
import FlashMessage from './components/flash-message/FlashMessage';

const App: FunctionComponent = (): ReactElement => (
	<FlashMessageProvider>
		<ThemeProvider>
			<QueryClientProvider>
				<RouterProvider />
				<FlashMessage />
			</QueryClientProvider>
		</ThemeProvider>
	</FlashMessageProvider>
);

export default App;
