/* eslint-disable react/jsx-no-constructed-context-values */
import {
	FunctionComponent,
	ReactElement,
	ReactNode,
	useState,
	createContext,
	useEffect,
} from 'react';
import { FlashPosition, FlashMessage as IFlashMessage } from '@/components/flash-message/types';

export interface IFlashMessageContext {
	open?: boolean;
	message?: IFlashMessage;
	position?: FlashPosition;
	onClose: () => void;
	setPosition: (position: FlashPosition) => void;
	dispatch: (message: IFlashMessage) => void;
	remove: () => void;
}

export const FlashMessageContext = createContext<IFlashMessageContext>({
	onClose: () => {},
	setPosition: () => {},
	dispatch: () => {},
	remove: () => {},
});

export const FlashMessageProvider: FunctionComponent<{ children: ReactNode }> = ({
	children,
}): ReactElement => {
	const [open, setOpen] = useState(false);
	const [position, setPosition] = useState<FlashPosition>({
		vertical: 'bottom',
		horizontal: 'right',
	});
	const [message, setMessage] = useState<IFlashMessage>();

	useEffect(() => message && setOpen(true), [message]);

	const onClose = () => setOpen(false);
	const remove = () => setMessage(undefined);
	const dispatch = (message: IFlashMessage) => {
		setMessage(message);
	};

	return (
		<FlashMessageContext.Provider
			value={{ open, onClose, message, position, setPosition, dispatch, remove }}
		>
			{children}
		</FlashMessageContext.Provider>
	);
};
