import { FunctionComponent, ReactElement, useEffect, useRef } from 'react';
import { Snackbar, Alert, AlertTitle, Portal, IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import CheckCircleOutline from '@mui/icons-material/CheckCircleOutlineOutlined';
import useFlashMessage from '@/context/flash-message-context';

const DEFAULT_MESSAGE_DURATION = 6000;

const FlashMessage: FunctionComponent = (): ReactElement => {
	const { open, onClose, message, position } = useFlashMessage();
	const timerRef = useRef<ReturnType<typeof setTimeout> | null>(null);
	const containerRef = useRef<HTMLElement>(document.getElementById('flash-message') as HTMLElement);

	const handleClose = (_: React.SyntheticEvent | Event, reason?: string) => {
		if (reason !== 'clickaway') onClose();
	};

	useEffect(() => {
		if (!message || message.disableSelfClosing) return undefined;

		if (timerRef.current) clearTimeout(timerRef.current);
		timerRef.current = setTimeout(() => onClose(), message.duration ?? DEFAULT_MESSAGE_DURATION);

		return () => {
			if (timerRef.current) clearTimeout(timerRef.current);
		};
	}, [message, onClose]);

	const closeAction = (
		<IconButton size="small" aria-label="close" color="inherit" onClick={handleClose}>
			<CloseIcon fontSize="small" />
		</IconButton>
	);

	return (
		<Portal container={containerRef.current}>
			<Snackbar open={open} anchorOrigin={position} onClose={handleClose}>
				<Alert
					icon={<CheckCircleOutline />}
					variant="filled"
					severity={message?.type}
					action={message?.showCloseButton && closeAction}
				>
					{message?.title && <AlertTitle>{message?.title}</AlertTitle>}
					{message?.content}
				</Alert>
			</Snackbar>
		</Portal>
	);
};

export default FlashMessage;
