/* eslint-disable import/no-absolute-path */
import { createTheme } from '@mui/material/styles';
import { common } from '@mui/material/colors';

import InterRegular from '/fonts/inter/Inter-Regular.woff2';
import InterItalic from '/fonts/inter/Inter-Italic.woff2';
import InterMedium from '/fonts/inter/Inter-Medium.woff2';
import InterMediumItalic from '/fonts/inter/Inter-MediumItalic.woff2';
import InterSemiBold from '/fonts/inter/Inter-SemiBold.woff2';
import InterSemiBoldItalic from '/fonts/inter/Inter-SemiBoldItalic.woff2';
import InterBold from '/fonts/inter/Inter-Bold.woff2';
import InterBoldItalic from '/fonts/inter/Inter-BoldItalic.woff2';

// Colors
const BLUE_PRIMARY = '#34abe3';

// Fonts
const MAIN_FONT = 'Inter';

const DEFAULT_THEME = createTheme();

const theme = createTheme({
	// typography: {
	// 	h1: { fontFamily: `${MAIN_FONT}, ${DEFAULT_THEME.typography.fontFamily}` },
	// 	h2: { fontFamily: `${MAIN_FONT}, ${DEFAULT_THEME.typography.fontFamily}` },
	// 	h3: { fontFamily: `${MAIN_FONT}, ${DEFAULT_THEME.typography.fontFamily}` },
	// 	h4: { fontFamily: `${MAIN_FONT}, ${DEFAULT_THEME.typography.fontFamily}` },
	// 	h5: { fontFamily: `${MAIN_FONT}, ${DEFAULT_THEME.typography.fontFamily}` },
	// 	h6: { fontFamily: `${MAIN_FONT}, ${DEFAULT_THEME.typography.fontFamily}` },
	// 	subtitle1: { fontFamily: `${MAIN_FONT}, ${DEFAULT_THEME.typography.fontFamily}` },
	// 	subtitle2: { fontFamily: `${MAIN_FONT}, ${DEFAULT_THEME.typography.fontFamily}` },
	// 	body1: { fontFamily: `${MAIN_FONT}, ${DEFAULT_THEME.typography.fontFamily}` },
	// 	body2: { fontFamily: `${MAIN_FONT}, ${DEFAULT_THEME.typography.fontFamily}` },
	// 	button: { fontFamily: `${MAIN_FONT}, ${DEFAULT_THEME.typography.fontFamily}` },
	// 	caption: { fontFamily: `${MAIN_FONT}, ${DEFAULT_THEME.typography.fontFamily}` },
	// 	overline: { fontFamily: `${MAIN_FONT}, ${DEFAULT_THEME.typography.fontFamily}` },
	// 	a: { fontFamily: `${MAIN_FONT}, ${DEFAULT_THEME.typography.fontFamily}` },
	// },

	typography: {
		fontFamily: `${MAIN_FONT}, ${DEFAULT_THEME.typography.fontFamily}`,
	},
	palette: {
		primary: {
			main: BLUE_PRIMARY,
			contrastText: common.white,
		},
	},
	components: {
		MuiCssBaseline: {
			styleOverrides: `
				@font-face: {
					font-family: 'Inter',
					src: url(${InterRegular}) format('woff2'),
					font-weight: 400,
					font-style: normal,
					font-display: swap,
				},
				@font-face: {
					font-family: 'Inter',
					src: url(${InterItalic}) format('woff2'),
					font-weight: 400,
					font-style: italic,
					font-display: swap,
				},
				@font-face: {
					font-family: 'Inter',
					src: url(${InterMedium}) format('woff2'),
					font-weight: 500,
					font-style: normal,
					font-display: swap,
				},
				@font-face: {
					font-family: 'Inter',
					src: url(${InterMediumItalic}) format('woff2'),
					font-weight: 500,
					font-style: italic,
					font-display: swap,
				},
				@font-face: {
					font-family: 'Inter',
					src: url(${InterSemiBold}) format('woff2'),
					font-weight: 600,
					font-style: normal,
					font-display: swap,
				},
				@font-face: {
					font-family: 'Inter',
					src: url(${InterSemiBoldItalic}) format('woff2'),
					font-weight: 600,
					font-style: italic,
					font-display: swap,
				},
				@font-face: {
					font-family: 'Inter',
					src: url(${InterBold}) format('woff2'),
					font-weight: 700,
					font-style: normal,
					font-display: swap,
				},
				@font-face: {
					font-family: 'Inter',
					src: url(${InterBoldItalic}) format('woff2'),
					font-weight: 700,
					font-style: italic,
					font-display: swap,
				}
			`,
		},
	},
});

export default theme;
